import React from "react"
import Label from "./Label"
import classes from "./Input.module.scss"

export default ({
  title,
  name,
  type,
  value,
  change = () => {},
  placeholder = "",
  required = false,
}) => {
  return (
    <div className={classes.FormGroup}>
      <Label name={name} title={title} />
      <input
        className={classes.Input}
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={change}
        placeholder={placeholder}
        required={required}
      />
    </div>
  )
}
