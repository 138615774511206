import React from "react"
import classes from "./Label.module.scss"

export default ({ name, title }) => {
  return (
    <label className={classes.Label} htmlFor={name}>
      {title}
    </label>
  )
}
