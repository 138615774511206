import React, { useState, useEffect } from "react"
import classes from "./ContactForm.module.scss"
import Input from "../Form/Input"
import Textarea from "../Form/Textarea"
import Button from "../Button/Button"

export default () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  })
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const subject = params.get("targy")
    if (subject) {
      const newFormData = {
        name: "",
        email: "",
        phone: "",
        subject: subject,
        message: "",
      }
      setFormData(newFormData)
    }
  }, [])

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    let currentFormData = { ...formData }
    currentFormData[e.target.name] = e.target.value
    setFormData(currentFormData)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => {
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 5000)
      })
      .catch(error => {
        console.warn(error)
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 5000)
      })
  }

  const notificationText = success
    ? "Köszönjük megkeresésedet, hamarosan felvesszük veled a kapcsolatot!"
    : error
    ? "Az üzenet elküldése közben hiba történt, kérjük vedd fel velünk a kapcsolatot telefonon vagy e-mailen."
    : null

  return (
    <div className={classes.ContactFormWrapper}>
      <form
        onSubmit={handleSubmit}
        name="contact"
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        className={classes.ContactForm}
      >
        <input type="hidden" name="bot-field" />
        <Input
          title="Név"
          type="text"
          name="name"
          required={true}
          change={handleChange}
          value={formData.name}
        />
        <Input
          title="E-mail"
          type="email"
          name="email"
          required={true}
          change={handleChange}
          value={formData.email}
        />
        <Input
          title="Telefonszám"
          type="tel"
          name="phone"
          required={false}
          change={handleChange}
          value={formData.phone}
        />
        <Input
          title="Tárgy"
          type="text"
          name="subject"
          required={false}
          change={handleChange}
          value={formData.subject}
        />
        <Textarea
          title="Üzenet"
          type="textarea"
          name="message"
          required={true}
          change={handleChange}
          value={formData.message}
        />
        <div className={classes.SubmitContainer}>
          <Button isSubmit={true}>Küldés</Button>
          {notificationText ? (
            <div className={classes.Notification}>{notificationText}</div>
          ) : null}
        </div>
      </form>
    </div>
  )
}
