import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import ContactForm from "../../components/ContactForm/ContactForm"
import ContentIcon from "../../components/ContentIcon/ContentIcon"
import messageIcon from "../../assets/images/message.png"
import phoneIcon from "../../assets/images/phone.png"

export default ({ data }) => {
  const sources = [
    data.file.childImageSharp.mobile,
    {
      ...data.file.childImageSharp.desktop,
      media: `(min-width: 900px)`,
    },
  ]

  return (
    <div>
      <Img fluid={sources} alt="kapcsolat" />
      <h1>Kapcsolat</h1>
      <div style={{ textAlign: "center" }}>
        <div>
          <ContentIcon img={messageIcon} name="e-mail" />
          <div style={{ marginTop: "0.5rem" }}>
            <a className="nolink" href="mailto:info@dream-day.hu">
              info@dream-day.hu
            </a>
          </div>
        </div>
        <div style={{ marginTop: "1.5rem" }}>
          <ContentIcon img={phoneIcon} name="telefon" />
          <div style={{ marginTop: "1rem" }}>
            <div style={{ marginBottom: "0.25rem" }}>Molnár Stella</div>
            <a className="nolink" href="tel:+36 20 383 9050">
              +36 20 383 9050
            </a>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <div style={{ marginBottom: "0.25rem" }}>Czakó Zsuzsanna</div>
            <a className="nolink" href="tel:+36 70 313 7831">
              +36 70 313 7831
            </a>
          </div>
        </div>
      </div>
      <h2>
        Rendezvényszervezőt keresel? <br />
        Írj nekünk bátran!
      </h2>
      <ContactForm />
    </div>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        desktop: fluid(maxWidth: 2000, maxHeight: 500, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
        mobile: fluid(maxWidth: 500, maxHeight: 250, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
